.product-item {
  display: flex;
  align-items: center;
  padding: 0.5em;
  font-size: 20px;
  overflow: hidden;
  border-radius: 0.3rem;
  background-repeat: no-repeat;
  background-position: top left;
  width: 100%;
  height: 83px;
  margin-bottom: 15px;
  user-select: none;
  cursor: pointer;
  border: 2px solid transparent;
}
.product-item:hover {
  box-shadow: 0 12px 54px 0 hsla(206, 57%, 12%, 0.1);
  border: 1px solid hsla(206, 57%, 12%, 0.1);
}
.product-item .logo {
  width: 4rem;
  height: 4rem;
  min-width: 4rem;
  overflow: hidden;
  border-radius: 0.2rem;
  background-color: transparent;
  background-size: 100% 100%;
}

.product-item .infos {
  justify-content: flex-start;
  flex-wrap: wrap;
  margin-left: 0.8em;
  margin-right: 0.5em;
  line-height: 1.3;
  text-decoration: none;
  overflow: hidden;
  flex-direction: column;
  flex-grow: 1;
}
.product-item .infos .product-name {
  padding: 0;
  margin: 0;
  font-weight: bold;
  text-decoration: none;
  margin-right: 0.5em;
  color: rgba(0, 0, 0, 0.85);
  flex-shrink: 0;
}

.product-item .infos .product-description {
  padding: 0;
  margin: 0;
  display: block;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
  color: #212529;
  text-decoration: none;
}

.product-item .user .profile-pic {
  height: 2em;
  width: 2em;
  border-radius: 2em;
  background-color: #ffffff;
  margin-right: 0.5em;
  background-size: 100% 100%;
}

.MuiAvatar-root.product-item-avatar {
  width: 4rem !important;
  height: 4rem !important;
  border-radius: 0.2rem !important;
  background-color: transparent;
}

.MuiBadge-colorSecondary {
  color: #fff;
  background-color: #f13a59 !important;
}
