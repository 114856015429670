.products-container {
  padding: 76px 0 120px;
  background-size: cover;
  -ms-flex-item-align: center;
  -ms-grid-row-align: center;
  align-self: center;
  min-height: 96vh;
  display: flex;
  flex-direction: column;
  position: relative;
  background-position: center center;
}

.btn.btn-dark.my-1 {
  font-weight: 600;
  padding: 0.75em;
  transition: 0.3s linear all;
  width: 250px !important;
  font-size: 20px;
}

.btn.btn-dark.my-1 {
  transition: 0.3s linear all;
}
