.bg-video {
  padding: 76px 0 120px;
  background-size: cover;
  -ms-flex-item-align: center;
  -ms-grid-row-align: center;
  align-self: center;
  position: relative;
  background-position: center center;
}

@media (max-width: 767px) {
  .bg-video {
    height: auto;
  }
}

.vjs_video_3-dimensions {
  height: 600px !important;
}

.video_titles {
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  -ms-flex-direction: row;
  -webkit-flex-direction: row;
  flex-direction: row;
  position: relative;
}

.vdo_imgs {
  display: inline-block;
  width: 45px;
  height: 45px;
  border-radius: 50%;
  background-color: transparent;
  overflow: hidden;
  margin-right: 10px;
}

.vdo_imgs img {
  width: 45px;
  height: 45px;
  border-radius: 50%;
}

.vdo_metas h5 {
  font-size: 11px;
  color: #9e9e9e;
  font-weight: 400;
  margin-bottom: 1px;
  margin-top: 1px;
}

.channel_meta h5 {
  font-size: 17px;
  color: #0f0f0f;
}

.vdo_metas p {
  font-size: 10px;
  color: #9e9e9e;
  margin-bottom: 0px;
}

video {
  width: 100%;
  height: auto;
}

.videoMetaRightIcon ul {
  list-style: none;
  /* display: flex; */
  /* padding-left: 30%; */
  width: 300px;
  max-width: 100%;
  float: right;
}

section.mainVideoSrc.container-fluid {
  padding: 0;
}
.videoMetaRightIcon ul li {
  width: 15%;
  text-align: center;
  float: right;
}

.videoMetaRightIcon ul li a {
  color: #171717;
  text-decoration: none;
  font-weight: 400;
}
.videoMetaRightIcon ul li a i {
  font-size: 16px;
  color: #909090 !important;
}
span.dots {
  font-size: 28px;
  position: absolute;
  top: -15px;
  right: 40px;
}

.videoTitle .vdo_metas {
  margin-right: 12%;
}
p.showMore {
  color: #9e9e9e;
  font-size: 15px;
  text-transform: uppercase;
  padding-top: 3px;
}
.vdo_metas h5 {
  font-size: 17px;
  color: #0f0f0f;
}
a.btn.btn-block.sub-btn {
  background: #f13a59;
  color: #fff;
  text-transform: uppercase;
  float: right;
}

.videoTitle h4 {
  font-size: 18px;
  margin-bottom: 5px;
}

.videoTitle h6 {
  font-size: 13px;
  margin-top: 0px;
  color: #646161;
  font-weight: 400;
  padding-bottom: 5px;
}

.commentsForm .vdo_metas {
  width: 100%;
}

.commentsForm input.form-control {
  width: 100%;
  border: 0px;
  border-bottom: 1px solid #9e9e9e;
  border-radius: 0px;
  padding-left: 0px;
  padding-bottom: 0px;
  font-size: 14px;
}

.commentsForm {
  margin-bottom: 25px;
}
.videoComments {
  margin-bottom: 20px;
  padding-left: 10px;
}
span.commentsCount {
  color: #000;
  font-size: 17px;
}

span.commentsSorts {
  color: #828181;
  padding-left: 15px;
}

.videoMeta p {
  margin-bottom: 0px;
}

hr.margin_top_z {
  margin-top: -5px;
}

.videTitle h4 {
  font-size: 18px;
  font-weight: 500;
}
.videoInfoHeader {
  padding-top: 20px;
}
.vdo_metas.videoCommentsMeta h4 {
  font-size: 16px;
  font-weight: 500;
  margin-bottom: 3px;
}
span.timeAgo {
  color: #8d8c8c;
  font-size: 16px;
  font-weight: 400;
}
.vdo_metas.videoCommentsMeta h5 {
  font-size: 16px;
  line-height: 20px;
  color: #000;
}
.vdo_metas.videoCommentsMeta p {
  padding: 8px 0px;
}
.vdo_metas.videoCommentsMeta p a {
  color: #9e9e9e;
  font-size: 14px;
  padding-right: 10px;
  text-decoration: none;
}

.videoCommentsItems.video_titles.position-relative.overflow-hidden {
  margin-bottom: 15px;
}
a.viewReply {
  font-size: 14px;
  font-weight: 500;
}

.vdo_metas.videoCommentsMeta {
  margin-top: -3px;
}
.form-control:focus {
  box-shadow: 0 0 0 0.2rem rgba(0, 123, 255, 0);
}

.vdo_imgs {
  display: inline-block;
  width: 45px;
  height: 45px;
  border-radius: 50%;
  background-color: transparent;
  overflow: hidden;
  margin-right: 10px;
  min-width: 45px;
  cursor: pointer;
}

.btnSubs {
  margin-right: 0;
  float: right;
}
p.total_views {
  font-size: 14px;
}

span.dates_views {
  font-size: 14px;
}

.videoMetaRightIcon ul li a {
  font-size: 14px;
}

.ui.comments {
  margin: 1.5em 0;
  max-width: none !important;
}

.ui.comments .comment .avatar img,
.ui.comments .comment img.avatar {
  border-radius: 45% !important;
}

.video-js {
  height: auto !important;
}

.channel_metas h4 {
  font-size: 14px;
  font-weight: 700;
}

.channel_metas h5 {
  font-size: 14px;
  font-weight: 400;
}

@media screen and (max-width: 767px) {
  .videoTitle .vdo_metas {
    margin-right: 15px;
    width: 100%;
  }
  .vdo_metas h5 {
    font-size: 12px;
    color: #0f0f0f;
  }
  .videoTitle h4 {
    font-size: 14px;
    margin-bottom: 5px;
  }
  p.showMore {
    color: #726f6f;
    font-size: 12px;
  }
  .videTitle h4 {
    font-size: 18px;
    font-weight: 500;
    line-height: initial;
    margin-bottom: 0px;
  }
  .videoMeta p {
    margin-bottom: 0px;
    font-size: 10px;
  }
  .videoMetaRightIcon ul {
    padding-left: 0px;
    float: left;
  }
  .videoMetaRightIcon ul li {
    text-align: left !important;
    float: left !important;
  }
  .videoMetaRightIcon ul li a {
    font-size: 16px;
    line-height: 0px;
  }
  a.btn.btn-block.sub-btn {
    font-size: 12px;
    font-weight: 500;
  }
  span.dots {
    font-size: 17px;
    position: relative;
    top: unset;
    right: unset;
  }
  span.commentsCount {
    color: #000;
    font-size: 15px;
  }
  span.commentsSorts {
    font-size: 14px;
  }
  .videoComments {
    margin-bottom: 15px;
  }
  .vdo_metas.videoCommentsMeta h5 {
    font-size: 12px;
    line-height: 16px;
    color: #000;
  }
  .vdo_metas.videoCommentsMeta p {
    padding: 5px 0px;
  }
  .vdo_metas.videoCommentsMeta h4 {
    font-size: 14px;
  }
  span.timeAgo {
    font-size: 13px;
  }
}

/* @media screen and (min-width: 1200px) {
  .videoInfos.container {
    width: 1140px;
  }
} */

@media screen and (min-width: 768px) and (max-width: 991px) {
  .videoTitle .vdo_metas {
    margin-right: 15px;
    width: 100%;
  }
}

@media screen and (min-width: 992px) and (max-width: 1024px) {
  .videoTitle .vdo_metas {
    margin-right: 15px;
    width: 100%;
  }
}

.ui.red.button,
.ui.red.buttons .button {
  color: #f13a59 !important;
  border-color: #f13a59 !important;
}

.ama_qa_thumbnail {
  opacity: 0.9;
  border-radius: 10px;
  height: 200px;
  width: auto;
  overflow: hidden;
  text-align: center;
  cursor: pointer;
}

@media screen and (max-width: 1024px) {
  .ama_qa_thumbnail {
    width: 250px;
    height: auto;
  }
}

.ama_qa_thumbnail:hover {
  opacity: 1;
}

.embed-icon {
  border-radius: 50%;
  background-color: #fff;
  border: 1px solid #dee2e6;
  width: 58px;
  height: 58px;
  cursor: pointer;
  margin-right: 21px;
}

.emojipicker {
  position: absolute;
}

.ui.comments .comment .text {
  white-space: break-spaces;
}

.reverse {
  flex-direction: row-reverse;
  padding-top: 0;
}

.wavy-underline {
  text-decoration: red underline wavy;
  margin-bottom: 0.5em;
}
