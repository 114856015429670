button.btn.btn-danger.ama-user {
  font-weight: 700;
  background: #f13a59;
  border-color: #f13a59;
  padding: 0.5em 2em;
}
button.btn.btn-danger.ama-user:hover {
  background: #e81035;
  border-color: #e81035;
}

.ama-user-card {
  border: 1px solid hsla(206, 57%, 12%, 0.1);
  border-radius: 5px;
  padding: 1.6rem !important;
}

.ama-user-card:hover {
  box-shadow: 0 12px 54px 0 hsla(206, 57%, 12%, 0.1);
  border: 1px solid hsla(206, 57%, 12%, 0.1);
  padding: 1.5rem !important;
}

span.ama-status {
  border: 1px solid #f13a59;
  color: #f13a59;
  padding: 2px 5px;
  position: absolute;
  top: 5px;
  right: 5px;
  border-radius: 2px;
  font-weight: bold;
  cursor: pointer;
}

.container-fluid {
  padding-right: 0 !important;
}
