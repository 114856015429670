.cta {
  background-position: center center;
  background-repeat: no-repeat;
  background-size: cover;
}

.hover-card {
  position: absolute;
  transform: translate(-50%, -50%);
  left: 50%;
  top: 50%;
  background: rgba(0, 0, 0, 0.51);
  width: 100%;
  height: 100%;
  transition: 0.5s linear all;
  opacity: 0;
}
.card.shadow-sm:hover .hover-card {
  opacity: 1;
}
.card-body h4 {
  font-size: 16px;
  margin-bottom: 0;
}
.hover-card a {
  position: absolute;
  bottom: 10px;
  right: 15px;
  color: #fff;
  text-decoration: none;
}

video.video-intro {
  position: absolute;
  width: 100%;
  transform: translate(-50%, -50%);
  left: 50%;
  top: 50%;
  height: 336px;
  object-fit: cover;
}

.introsection-div {
  position: relative;
  z-index: 999;
}

@media screen and (min-width: 240px) and (max-width: 480px) {
  video.video-intro {
    position: absolute;
    width: 100%;
    transform: translate(-50%, -50%);
    left: 50%;
    top: 50%;
    height: 360px;
    object-fit: cover;
  }
}

@media screen and (min-width: 480px) and (max-width: 600px) {
  video.video-intro {
    position: absolute;
    width: 100%;
    transform: translate(-50%, -50%);
    left: 50%;
    top: 50%;
    height: 360px;
    object-fit: cover;
  }
  .col-md-2 {
    -ms-flex: 0 0 50%;
    flex: 0 0 50%;
    max-width: 50%;
  }
}

@media screen and (min-width: 601px) and (max-width: 767px) {
  .col-md-2 {
    -ms-flex: 0 0 33.33%;
    flex: 0 0 33.33%;
    max-width: 33.33%;
  }

  video.video-intro {
    position: absolute;
    width: 100%;
    transform: translate(-50%, -50%);
    left: 50%;
    top: 50%;
    height: 360px;
    object-fit: cover;
  }
}

@media screen and (min-width: 768px) and (max-width: 1024px) {
  video.video-intro {
    position: absolute;
    width: 100%;
    transform: translate(-50%, -50%);
    left: 50%;
    top: 50%;
    height: 360px;
    object-fit: cover;
  }
  .col-md-2 {
    -ms-flex: 0 0 25%;
    flex: 0 0 25%;
    max-width: 25%;
  }
  .vdo_img img {
    width: 30px;
    height: 30px;
    border-radius: 50%;
    max-width: 100%;
  }
  .card-body h4 {
    font-size: 14px;
    margin-bottom: 0;
  }
  .vdo_meta p {
    font-size: 9px;
    color: #9e9e9e;
    margin-bottom: 0px;
  }
}

section.jumbotron.text-center.cta {
  position: relative;
}
section.jumbotron.text-center.cta:after {
  position: absolute;
  content: "";
  background: rgba(0, 0, 0, 0.25);
  transform: translate(-50%, -50%);
  top: 50%;
  left: 50%;
  width: 100%;
  height: 100%;
  z-index: -9;
}

.jumbotron {
  background-color: #fff;
}

.introsection-div *,
.introsection-div p {
  color: #fff !important;
}

.card {
  height: 100%;
}

.card:hover {
  transform: scale(1.01);
  transition: all 0.3s ease-in-out;
}

.card-image {
  width: 100%;
  overflow: hidden;
  margin: 0;
  padding-top: 56.25%;
  position: relative;
  cursor: pointer;
}

.card-image img {
  position: absolute;
  top: 50%;
  left: 50%;
  width: 100%;
  transform: translate(-50%, -50%);
}

.vdo_img {
  float: left;
  width: 25%;
}

.vdo_img img {
  width: 45px;
  height: 45px;
  border-radius: 50%;
}

.vdo_meta {
  float: left;
  width: 72%;
  padding-left: 3px;
}
.vdo_meta h5 {
  font-size: 11px;
  color: #9e9e9e;
  font-weight: 400;
  margin-bottom: 1px;
  margin-top: 1px;
}
span.verified {
  background: #607d8b;
  width: 10px;
  height: 5px;
  padding: 2px 4px;
  border-radius: 50%;
  border: 0px;
  color: #fff;
  font-size: 6px;
}

.vdo_meta p {
  font-size: 10px;
  color: #9e9e9e;
  margin-bottom: 0px;
}

.card-body {
  padding: 0.75rem !important;
}

span.play_time {
  background: #3b3c3c;
  color: #fff;
  padding: 2px 5px;
  position: absolute;
  bottom: 10px;
  right: 10px;
  border-radius: 2px;
  font-weight: bold;
}

a.play_btn {
  background: #3b3c3c;
  color: #fff;
  left: 0;
  display: inline-block;
  width: 50px;
  text-align: center;
  font-size: 40px;
  line-height: 20px;
  padding-bottom: 6px;
  border-radius: 5px;
  left: 10px;
  bottom: 10px;
  padding-top: 2px;
}

a.play_btn:hover {
  background: #3b3c3c;
  color: #f13a59;
  left: 0;
  display: inline-block;
  width: 50px;
  text-align: center;
  font-size: 40px;
  line-height: 20px;
  padding-bottom: 6px;
  border-radius: 5px;
  left: 10px;
  bottom: 10px;
  padding-top: 2px;
}

a:hover {
  text-decoration: inherit !important;
}

@media screen and (min-width: 480px) and (max-width: 600px) {
  .col-md-2 {
    -ms-flex: 0 0 50%;
    flex: 0 0 50%;
    max-width: 50%;
  }
}

@media screen and (min-width: 601px) and (max-width: 767px) {
  .col-md-2 {
    -ms-flex: 0 0 33.33%;
    flex: 0 0 33.33%;
    max-width: 33.33%;
  }
}

@media screen and (min-width: 768px) and (max-width: 1024px) {
  .col-md-2 {
    -ms-flex: 0 0 25%;
    flex: 0 0 25%;
    max-width: 25%;
  }
  .vdo_img img {
    width: 30px;
    height: 30px;
    border-radius: 50%;
    max-width: 100%;
  }
  .card-body h4 {
    font-size: 14px;
    margin-bottom: 0;
  }
  .vdo_meta p {
    font-size: 9px;
    color: #9e9e9e;
    margin-bottom: 0px;
  }
}

@media screen and (min-width: 1025px) and (max-width: 1180px) {
  .col-md-2 {
    -ms-flex: 0 0 25%;
    flex: 0 0 25%;
    max-width: 25%;
  }
}

@media screen and (min-width: 1181px) and (max-width: 1280px) {
  .col-md-2 {
    -ms-flex: 0 0 20%;
    flex: 0 0 20%;
    max-width: 20%;
  }
}

@media screen and (min-width: 768px) and (max-width: 800px) {
  .col-md-2 {
    -ms-flex: 0 0 33.33%;
    flex: 0 0 33.33%;
    max-width: 33.33%;
  }
}

img {
  max-width: 100%;
  width: 100%;
}

.vdo_img {
  text-align: center;
}

.product-hashtag {
  background-color: #ffdde2;
}

.product-hashtag:hover {
  background-color: #f8d2d7;
}

@import url("https://fonts.googleapis.com/css2?family=Source+Sans+Pro:wght@200;300;400;600;700;900&display=swap");

body {
  box-sizing: border-box;
  background-color: #0e2439;
  font-family: "Source Sans Pro", "Helvetica Neue", Helvetica, sans-serif;
  font-size: 14px;
}
.main {
  width: 1000px;
  margin: 0 auto;
}
.hidden {
  display: none;
}
.user-link {
  position: relative;
}
.user-link--span {
  height: 28px;
  width: 28px;
  background-color: #1f364d;
  border: 3px solid #1f364d;
  border-radius: 100px;
  position: relative;
  display: block;
}
.user-link__avatar {
  border-radius: 100px;
  height: 100%;
  object-fit: cover;
  width: 100%;
  display: block;
}
.user-link:hover .user-link--span {
  box-shadow: 0 0 0 2px #fff;
}

.product-card {
  position: absolute;
  top: calc(100% + 12px);
  left: 0;

  align-items: center;
  background-color: #ffffff;
  border-radius: 3px;
  box-shadow: 0 0 30px 16px rgba(49, 49, 49, 0.5);
  color: #ffffff;
  display: flex;
  flex-direction: column;
  align-items: center;
  font-size: 16px;
  min-height: 60px;
  width: 300px;
  /* overflow: hidden; */
  text-align: left;
  z-index: 130;
}
.product-card .product-card__header {
  align-items: center;
  background-color: #ffffff;
  border-top-left-radius: 3px;
  border-top-right-radius: 3px;
  display: flex;
  padding: 10px 10px;
  position: relative;
  transition: background-color 180ms;
  width: 100%;
  box-sizing: border-box;
  text-decoration: none;
}
.product-card .product-card__header:hover {
  background-color: #36597c;
}
.product-card .product-card__logo {
  height: 48px;
  width: 48px;
  background-color: #fff;
  border-radius: 9999px;
  box-shadow: 0 0 0 2px #fff;
  flex: 0 0 auto;
}
.product-card .user-avatar__img {
  border-radius: 9999px;
  height: 100%;
  object-fit: cover;
  width: 100%;
}
.product-card .product-card__names {
  display: flex;
  flex-direction: column;
  margin-left: 11px;
}
.product-card .product-card__full-name {
  color: #030303;
  font-size: 20px;
  font-weight: 600;
}
.product-card .product-card__product-pitch {
  color: #030303;
  margin-top: 3px;
  font-size: 14px;
}
.product-card .product-card__bio {
  margin-top: 5px;
  padding: 13px 18px !important;
  transition: background-color 180ms;
  width: 100%;
  box-sizing: border-box;
  text-decoration: none;
  color: #fff;
}
.product-card .product-card__bio:hover {
  background-color: #2c4763;
  color: #b3cce6;
}
.product-card .product-card__bio > p {
  display: -webkit-box;
  overflow: hidden;
  -webkit-line-clamp: 2;
  text-overflow: ellipsis;
  max-height: 44px;
  -webkit-box-orient: vertical;
  cursor: pointer;
  line-height: 22px;
  white-space: normal;
  margin: 0;
}
.product-card .product-card__items {
  display: flex;
  flex-direction: column;
  margin: 0 auto;
  width: 100%;
}
.product-card .product-card__item {
  display: flex;
  flex-direction: column;
  max-width: 300px;
  padding: 9px 18px;
  text-align: left;
  text-overflow: ellipsis;
}
.product-card a.product-card__item {
  cursor: pointer;
  transition: background-color 180ms;
  color: #fff;
  text-decoration: none;
}
.product-card a.product-card__item:hover {
  background-color: #2c4763;
}
.product-card a.product-card__item > label {
  color: #b3cce6;
}
.product-card__item--para {
  display: flex;
  align-items: center;
}
.product-card a.product-card__item p {
  color: #4799eb;
  font-weight: 600;
  margin: 0;
}
.product-card a.product-card__item > label,
.product-card a.product-card__item p {
  transition: color 180ms;
  cursor: pointer;
}
.product-card .product-card__item .product-avatar {
  height: 18px;
  width: 18px;
  flex: 0 0 auto;
  margin-right: 6px;
}
.product-avatar .product-avatar__img {
  height: 100%;
  width: 100%;
  border-radius: 9999px;
}
.product-card .product-card__item > label,
.product-card .product-card__item p {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
.product-card .product-card__item > label {
  font-size: 13px;
}
.product-card .product-card__item p {
  align-items: center;
  color: #fff;
  display: flex;
  font-size: 18px;
  margin-top: 2px;
  margin: 0;
}
.product-card .product-card__item-row {
  display: flex;
}
.product-card .product-card__item-row .product-card__item {
  flex: 0 0 auto;
}
.product-card .product-card__footer {
  align-items: center;
  background-color: #22384e;
  display: flex;
  margin-top: 9px;
  padding: 11px;
  width: 100%;
  box-sizing: border-box;
}
.product-card .product-card__follow-button {
  flex: 1 0 auto;
  height: 42px;
  padding: 0 12px;
  width: auto;
}
.follow-button {
  color: #fff;
  cursor: pointer;
  background-image: linear-gradient(to right, #e052a0, #f15c41) !important;
  border-radius: 3px;
  box-shadow: 12px 12px 10px rgba(0, 0, 0, 0.08);
  display: flex;
  font-size: 16px;
  font-weight: 600;
  white-space: nowrap;
  width: 100%;
  border: none;
}
.follow-button--not-following,
.follow-button:hover:not([disabled]) {
  background-image: linear-gradient(to right, #3ec7e0, #526bf4) !important;
}
.follow-button > div {
  align-items: center;
  display: flex;
  justify-content: center;
  width: 100%;
}
.follow-button > div > svg {
  height: 20px;
  width: 20px;
  fill: #fff;
  margin-right: 8px;
}
.product-card .product-card__contact-button {
  height: 19px;
  width: 19px;
  align-items: center;
  border-radius: 3px;
  display: flex;
  flex: 0 0 auto;
  justify-content: center;
  margin: 0 2px 0 11px;
}
.product-card .product-card__contact-icon {
  height: 100%;
  width: 100%;
  fill: #63809c;
}
.product-card .product-card__contact-button:hover .product-card__contact-icon {
  fill: #fff;
}
