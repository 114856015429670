.bg-messages {
  padding: 120px 0 120px;
  background-size: cover;
  -ms-flex-item-align: center;
  -ms-grid-row-align: center;
  align-self: center;
  position: relative;
  min-height: 95vh;
  background-position: center center;
}

.bg-messages.messages {
  display: flex;
  flex: 1 1 auto;
  flex-direction: column;
  justify-content: space-between;
}

.bg-messages.messages.top-wrap {
  display: flex;
  flex-direction: column;
  flex: 1 1 auto;
  margin-top: 20px;
  margin-bottom: 20px;
}

.ui.feed > .event > .content .extra.text {
  max-width: 100%;
}

.ui.form .field > label {
  display: block;
  margin: 0 0 10px;
  color: #565656;
  font-size: 16px;
  font-weight: 600;
  text-transform: none;
}

.btn.btn-lazy-post {
  font-weight: 700;
  background: #f13a59;
  border-color: #f13a59;
  padding: 0.75em 1.5em;
  transition: 0.3s linear all;
  color: #fff;
}
.btn.btn-lazy-post:hover {
  background: #e81035;
  border-color: #e81035;
  color: #fff;
}

@media screen and (min-width: 1200px) {
  .lazyUpdate.container {
    width: 750px;
  }
}
