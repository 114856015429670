.todo-box {
  padding: 0 !important;
  /* border: none !important; */
}

.todo {
  width: 100%;
  margin: 0 auto;
  text-align: left;
  font-size: 16px;
}

.form-inline .form-control {
  width: 100%;
  font-size: 16px;
}

.done {
  color: #888888;
  text-decoration: line-through;
}

.icon {
  margin: 6px 10px 6px 0;
}

.form-inline .form-control {
  display: inline-block;
  vertical-align: middle;
  padding: 1.5rem 1.25rem;
  width: 100% !important;
}

.list-group-item:first-child {
  border-top-left-radius: 0 !important;
  border-top-right-radius: 0 !important;
}
.list-group-item:last-child {
  border-bottom-right-radius: 0 !important;
  border-bottom-left-radius: 0 !important;
}
