.sidebar-header {
  color: #464646;
}

.video-product-item {
  display: flex;
  align-items: center;
  padding: 0.5em;
  font-size: 20px;
  overflow: hidden;
  border-radius: 0.3rem;
  background-repeat: no-repeat;
  background-position: top left;
  width: 100%;
  height: auto;
  margin-bottom: 5px;
  user-select: none;
  cursor: pointer;
  border: 2px solid transparent;
}

.video-product-item .logo {
  width: 3rem;
  height: 3rem;
  min-width: 3rem;
  overflow: hidden;
  border-radius: 0.2rem;
  background-color: transparent;
  background-size: 100% 100%;
}

.video-product-item .infos {
  justify-content: flex-start;
  flex-wrap: wrap;
  margin-left: 0.8em;
  margin-right: 0.5em;
  line-height: 1.3;
  text-decoration: none;
  overflow: hidden;
  flex-direction: column;
  flex-grow: 1;
}
.video-product-item .infos .video-product-name {
  padding: 0;
  margin: 0;
  font-weight: bold;
  text-decoration: none;
  margin-right: 0.5em;
  color: #464646;
  flex-shrink: 0;
  font-size: 16px;
}

.video-product-item .infos .video-product-name:hover {
  color: rgba(0, 0, 0, 0.85);
}

.video-product-item .infos .video-product-description {
  padding: 0;
  margin: 0;
  display: block;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
  color: #212529;
  text-decoration: none;
  font-weight: 400;
}

.video-product-item .user img.profile-pic {
  height: 2em !important;
  width: 2em !important;
  border-radius: 2em !important;
  background-color: transparent;
  margin-right: 0.5em;
  background-size: 100% 100%;
}

/* For next videos */
.next-video-item {
  display: flex;
  align-items: center;
  padding: 0.5em;
  font-size: 20px;
  overflow: hidden;
  border-radius: 0.3rem;
  background-repeat: no-repeat;
  background-position: top left;
  width: 100%;
  height: auto;
  margin-bottom: 5px;
  user-select: none;
  cursor: pointer;
  border: 2px solid transparent;
}

.next-video-item .thumbnail {
  width: 130px;
  height: 73px;
  min-width: 130px;
  overflow: hidden;
  border-radius: 0.3rem;
  background-color: transparent;
  background-size: 100% 100%;
}

.next-video-item .infos {
  justify-content: flex-start;
  flex-wrap: wrap;
  margin-left: 0.8em;
  margin-right: 0.5em;
  line-height: 1;
  text-decoration: none;
  overflow: hidden;
  flex-direction: column;
  flex-grow: 1;
}
.next-video-item .infos .next-video-title {
  padding: 0;
  margin: 0;
  font-weight: bold;
  text-decoration: none;
  margin-right: 0.5em;
  color: #464646;
  flex-shrink: 0;
  font-size: 16px;
}

.next-video-item .infos .next-video-title:hover {
  color: rgba(0, 0, 0, 0.85);
}

.next-video-item .infos .next-video-user {
  padding: 0;
  margin: 0;
  margin-top: 2px;
  display: block;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
  color: #778791;
  text-decoration: none;
  font-weight: 700;
  font-size: 14px;
}

.next-video-item .infos .next-video-user:hover {
  color: #545454;
}

.MuiBadge-colorSecondary {
  color: #fff;
  background-color: #f13a59 !important;
}

.subscription-form {
  display: flex;
  align-items: center;
  padding: 0.5em;
  font-size: 16px;
  overflow: hidden;
  border-radius: 0.3rem;
  background-repeat: no-repeat;
  background-position: top left;
  width: 100%;
  height: auto;
  margin-bottom: 5px;
  user-select: none;
  cursor: pointer;
  border: 2px solid transparent;
}

.subscription-form .input-email {
  border: 1px solid rgba(0, 0, 0, 0.1);
}

.subscription-form .subscribe-btn {
  justify-content: flex-start;
  flex-wrap: wrap;
  margin-left: 1em;
  margin-right: 1em;
  line-height: 1.3;
  text-decoration: none;
  flex-direction: row;
  flex-grow: 1;
  width: 200px;
}

ol,
ul {
  list-style: decimal;
  margin: 0;
  list-style-position: inside;
}

/* ConvertKid form */
/* .ck-form.formkit-form.clare {
  display: flex;
} */
.ck-form.formkit-form.clare .formkit-powered-by {
  display: none;
}

.formkit-form.clare .formkit-input {
  background: #ffffff;
  font-size: 15px;
  padding: 6px;
  border: 1px solid #e3e3e3;
  -webkit-flex: 1 0 auto;
  -ms-flex: 1 0 auto;
  flex: 1 0 auto;
  line-height: 1.4;
  margin: 0;
  -webkit-transition: border-color ease-out 300ms;
  transition: border-color ease-out 300ms;
}

.ck-form.formkit-form.clare .formkit-button > span,
.formkit-form.clare .formkit-submit > span {
  padding: 6px 24px;
}
