.say-hi {
  cursor: pointer;
  padding: 5px;
  border-radius: 5px;
  border: 1px solid #e9e9e9;
}

.say-hi:hover {
  background-color: #f6eedf;
}

.said-hi {
  cursor: pointer;
  padding: 5px;
  border-radius: 5px;
  background-color: #f6eedf;
  border: 1px solid #fae1b2;
}

.waving-hand {
  animation-name: wave-animation; /* Refers to the name of your @keyframes element below */
  animation-duration: 2.5s; /* Change to speed up or slow down */
  animation-delay: 2s;
  animation-iteration-count: infinite;
  animation-direction: normal;
  animation-fill-mode: none;
  animation-play-state: running;
}

@keyframes wave-animation {
  5% {
    transform: rotate(20deg);
  }
  8% {
    transform: rotate(-20deg);
  }
  11% {
    transform: rotate(15deg);
  }
  14% {
    transform: rotate(-15deg);
  }
  17% {
    transform: rotate(10deg);
  }
  20% {
    transform: rotate(-10deg);
  }
  23% {
    transform: rotate(5deg);
  }
  26% {
    transform: rotate(-5deg);
  }
  29% {
    transform: rotate(0deg);
  }
}
