.rsvp-card {
  border: 1px solid #e2e8f0;
  border-radius: 5px;
}

.meetup-description-markdown ul {
  padding-left: 1.5em;
  display: block;
  list-style-type: disc;
  margin-block-start: 1em;
  margin-block-end: 1em;
  margin-inline-start: 0px;
  margin-inline-end: 0px;
  padding-inline-start: 30px;
}
