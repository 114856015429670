.meetup-item {
  display: flex;
  align-items: center;
  padding: 2em 1em;
  font-size: 16px;
  overflow: hidden;
  border-radius: 0.3rem;
  background-repeat: no-repeat;
  background-position: top left;
  width: 100%;
  margin-bottom: 15px;
  user-select: none;
  cursor: pointer;
  border: 1px solid hsla(206, 57%, 12%, 0.1);
}
.meetup-item:hover {
  box-shadow: 0 12px 54px 0 hsla(206, 57%, 12%, 0.1);
  transform: scale(1.01);
  transition: all 0.3s ease-in-out;
}

.meetup-item .meetup-date {
  align-items: center;
  background-color: #fff;
  border-radius: 4px;
  display: flex;
  flex: 0 0 auto;
  flex-direction: column;
  justify-content: center;
  text-align: center;
  padding: 1em;
  border: 1px solid #e2e8f0;
}
/* @media (min-width: 600px)
.meetups .meetup-date {
  height: 60px;
  width: 58px;
} */

/* @media (min-width: 600px)
.meetups .meetup-date-month {
    font-size: 15px;
} */

.meetup-item .meetup-date-month {
  color: #1a202c;
  font-weight: 600;
  line-height: 2em;
  text-transform: uppercase;
}

/* @media (min-width: 600px) .meetups .meetup-date-day {
  font-size: 22px;
} */
.meetup-item .meetup-date-day {
  color: #667eea;
  font-weight: 600;
  line-height: 1em;
  margin: 3px auto 0;
}

.meetup-item .logo {
  width: 4rem;
  height: 4rem;
  min-width: 4rem;
  overflow: hidden;
  border-radius: 0.2rem;
  background-color: transparent;
  background-size: 100% 100%;
}

.meetup-item .infos {
  justify-content: flex-start;
  flex-wrap: wrap;
  margin-left: 2em;
  margin-right: 2em;
  line-height: 1.3;
  text-decoration: none;
  overflow: hidden;
  flex-direction: column;
  flex-grow: 1;
  max-width: 500px;
}
.meetup-item .infos .meetup-name {
  padding: 0;
  margin: 0;
  font-weight: bold;
  font-size: 24px;
  text-decoration: none;
  margin-right: 0.5em;
  color: rgba(0, 0, 0, 0.85);
  flex-shrink: 0;
}

.meetup-item .infos .meetup-time {
  padding: 0;
  margin: 0;
  margin-bottom: 0.5em;
  display: block;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
  color: #212529;
  text-decoration: none;
}

.meetup-item .infos .meetup-description {
  padding: 0;
  margin: 0;
  margin-bottom: 0.8em;
  display: block;
  text-overflow: ellipsis;
  overflow: hidden;
  color: #212529;
  text-decoration: none;
}

.meetup-item .user .profile-pic {
  height: 2em;
  width: 2em;
  border-radius: 2em;
  background-color: #ffffff;
  margin-right: 0.5em;
  background-size: 100% 100%;
}

.MuiAvatar-root.meetup-item-avatar {
  width: 4rem !important;
  height: 4rem !important;
  border-radius: 0.2rem !important;
  background-color: transparent;
}

.MuiBadge-colorSecondary {
  color: #fff;
  background-color: #f13a59 !important;
}

.MuiAvatar-root.meetup-attendee {
  width: 25px;
  height: 25px;
}
