.invite-container {
  min-height: 95vh;
  display: flex;
  flex-direction: column;
  min-width: 100vw;
  padding: 76px 0 120px;
}

@media (max-width: 767px) {
  .bg-settings {
    padding: 150px 0;
    height: auto;
  }
}

.grey-background {
  background-color: #f6f9fc;
}

.card-content {
  .form-content {
    .custom-control-input-blue:focus ~ .custom-control-label::before {
      border-color: #77b6ea !important;
      box-shadow: 0 0 0 0.2rem rgba(40, 167, 69, 0.25) !important;
    }

    .custom-control-input-blue:checked ~ .custom-control-label::before {
      border-color: #77b6ea !important;
      background-color: #77b6ea !important;
    }

    .custom-control-input-blue:focus:not(:checked)
      ~ .custom-control-label::before {
      border-color: #77b6ea !important;
    }

    .custom-control-input-blue:not(:disabled):active
      ~ .custom-control-label::before {
      background-color: #d6f5dd !important;
      border-color: #d6f5dd !important;
    }
    label {
      font-weight: 600;
    }
  }
  .card-header {
    color: #3c4858;
    letter-spacing: -0.1px;
    font-weight: 700;
    font-size: 26px;
  }
  .aside {
    border-left: 1px solid #ced4da;
  }
  .image-wrap {
    width: 150px;
    height: auto;
    overflow: hidden;
    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  }
}
