.footer {
  background: #fff;
  padding: 60px 0;
  position: relative;
  color: #adb5bd;
}

.footer .logo-footer {
  color: #f8f9fc;
  font-weight: 700;
  font-size: 24px;
  padding: 3px 0;
  letter-spacing: 1px;
}

.footer .footer-head {
  font-size: 20px;
  letter-spacing: 1px;
  font-weight: 500;
}

.footer .foot-subscribe .form-control {
  background-color: #25304e;
  border: 1px solid #25304e;
  color: #f8f9fc;
}

.footer .foot-subscribe .form-control:focus {
  -webkit-box-shadow: none;
  box-shadow: none;
}

.footer .foot-subscribe.foot-white .form-control {
  color: #adb5bd;
}

.footer .foot-subscribe input::-webkit-input-placeholder {
  color: #adb5bd;
}

.footer .foot-subscribe input:-ms-input-placeholder {
  color: #adb5bd;
}

.footer .foot-subscribe input::-ms-input-placeholder {
  color: #adb5bd;
}

.footer .foot-subscribe input::placeholder {
  color: #adb5bd;
}

.footer .text-foot {
  color: #adb5bd;
}

.footer .footer-list {
  margin-bottom: 0;
}

.footer .footer-list li {
  margin-bottom: 10px;
}

.footer .footer-list li a {
  -webkit-transition: all 0.5s ease;
  transition: all 0.5s ease;
}

.footer .footer-list li a:hover {
  color: #e6e8ea;
}

.footer .footer-list li:last-child {
  margin-bottom: 0;
}

.footer.footer-bar {
  padding: 20px 0;
}

.footer.footer-bar .payment-cards img {
  height: 20px;
}
