.weekly-topic-meta {
  display: flex;
  justify-content: center;
  align-items: center;
}

.weekly-topic-meta h5 {
  font-size: 14px;
  color: #757575;
  font-weight: 400;
  margin-bottom: 1px;
  margin-top: 1px;
}

div.weekly_topic {
  color: #fff;
  padding: 2px 5px;
  position: absolute;
  top: 10px;
  left: 10px;
  font-weight: bold;
  font-size: 18px;
}

.weekly-topic-time {
  font-size: 25px;
}
