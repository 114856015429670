.bg-notifications {
  padding: 120px 0 120px;
  min-height: 95vh;
  background-size: cover;
  -ms-flex-item-align: center;
  -ms-grid-row-align: center;
  align-self: center;
  position: relative;
  background-position: center center;
}

@media (max-width: 767px) {
  .bg-notifications {
    padding: 100px 0;
    height: auto;
  }
}

.ui.feed > .event > .content .extra.text {
  max-width: 100%;
}

.ui.form .field > label {
  display: block;
  margin: 0 0 10px;
  color: #565656;
  font-size: 16px;
  font-weight: 600;
  text-transform: none;
}

.btn.btn-lazy-post {
  font-weight: 700;
  background: #f13a59;
  border-color: #f13a59;
  padding: 0.75em 1.5em;
  transition: 0.3s linear all;
  color: #fff;
}
.btn.btn-lazy-post:hover {
  background: #e81035;
  border-color: #e81035;
  color: #fff;
}

@media screen and (min-width: 1200px) {
  .notifications.container {
    width: 900px;
  }
}

.scroll-notification {
  height: 250px;
  overflow-y: scroll;
}
.dropdown-item {
  padding: 0.25rem 1rem !important;
}
.post-content {
  max-width: 200px;
}
.new-topic {
  color: grey;
  font-size: 12px;
  margin-bottom: 0;
}
.dropdown-notification {
  cursor: pointer;
  margin-left: auto;
  min-height: 100px;
  border: solid 2px #c1c1c1;
  border-radius: 5px;
  position: absolute;
  z-index: 10;
  right: 22px;
  margin-top: -10px;
  background-color: #ffffff;
}
.dropdown-hide {
  display: none;
}
.dropdown-toggle::after {
  content: none !important;
}
.notification-footer {
  text-align: center;
  color: grey;
  padding-bottom: 7px;
}
.dropdown-menu {
  right: 0;
  left: auto !important;
  width: 360px;
}
.dropdown-item {
  white-space: normal !important;
}
::-webkit-scrollbar {
  width: 7px;
  height: 20px;
}
::-webkit-scrollbar-thumb {
  background: grey;
  border-radius: 15px;
}
.option-button {
  position: absolute;
  top: 0.4em;
  left: 16.8em;
}
.popov-property {
  cursor: pointer;
}
.option {
  z-index: 10;
  background-color: #fff;
}
.dropdown-option {
  width: 100px;
}
.btn-light:focus {
  box-shadow: none !important;
}
.btn-light:hover {
  background-color: #ffffff !important;
  border-color: #ffffff !important;
}
.btn-light {
  background-color: #ffffff !important;
  border-color: #ffffff !important;
}
.show-more-option {
  cursor: pointer;
  margin-left: auto;
  padding: 0 5px;
  min-height: 20px;
  border: solid 1px #c1c1c1;
  border-radius: 3px;
  position: absolute;
  z-index: 10;
  right: 32px;
  margin-top: -15px;
  background-color: #ffffff;
}
.navbar {
  width: 100%;
}
.notification-header {
  padding: 8px 15px;
}
.notification-header-title {
  color: #565656;
  font-size: 18px;
  font-weight: 500;
  display: "inline";
}
.notification-header-icon {
  margin-top: 3px;
}
.wrap-text {
  margin-bottom: 0;
}
.notification-footer-title {
  margin-bottom: 0;
  padding-top: 5px;
}
.event.notification-item {
  position: relative;
  padding: 6px 15px !important;
  display: flex;
  border-bottom: 0.5px solid rgb(233, 236, 239);
}
.event.notification-item:hover {
  background-color: #f1f3f4;
}
.more-option {
  display: none !important;
  background-color: #f1f3f4 !important;
  border-color: #f1f3f4 !important;
  cursor: pointer;
  position: absolute;
  top: 10px;
  right: 10px;
}

.event.notification-item:hover .more-option {
  display: inline-block !important;
}
.dropdown-divider {
  margin: 0 !important;
}
.nav-bar {
  z-index: 5;
  position: sticky;
  top: 0;
}

.ui.feed > .event > .content .extra.images img {
  border-radius: 0.8em;
  width: 15em;
}
