.week-product-item {
  display: flex;
  align-items: center;
  padding: 0.5em;
  font-size: 20px;
  overflow: hidden;
  border-radius: 0.3rem;
  background-repeat: no-repeat;
  background-position: top left;
  width: 100%;
  height: auto;
  margin-bottom: 5px;
  user-select: none;
  cursor: pointer;
  border: 2px solid transparent;
}
.week-product-item:hover {
  transform: scale(1.02);
  transition: all 0.3s ease-in-out;
}
.ui.feed > .event > .content .week-product-item .MuiBadge-root img.logo {
  width: 3rem !important;
  height: 3rem !important;
  min-width: 3rem;
  min-height: 3rem;
  overflow: hidden;
  border-radius: 0.2rem;
  background-color: transparent;
  background-size: 100% 100%;
}

.week-product-item .infos {
  justify-content: flex-start;
  flex-wrap: wrap;
  margin-left: 0.8em;
  margin-right: 0.5em;
  line-height: 1.3;
  text-decoration: none;
  overflow: hidden;
  flex-direction: column;
  flex-grow: 1;
}
.week-product-item .infos .week-product-name {
  padding: 0;
  margin: 0;
  font-weight: bold;
  text-decoration: none;
  margin-right: 0.5em;
  color: rgba(0, 0, 0, 0.85);
  flex-shrink: 0;
}

.week-product-item .infos .week-product-description {
  padding: 0;
  margin: 0;
  display: block;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
  color: #212529;
  text-decoration: none;
  font-weight: 400;
}

.week-product-item .user img.profile-pic {
  height: 2em !important;
  width: 2em !important;
  border-radius: 2em !important;
  background-color: transparent;
  margin-right: 0.5em;
  background-size: 100% 100%;
}

.MuiBadge-colorSecondary {
  color: #fff;
  background-color: #f13a59 !important;
}

.MuiAvatar-root.this-week-product-avatar {
  width: 3rem !important;
  height: 3rem !important;
  border-radius: 0.2rem !important;
}
