.bg-dashboard {
  padding: 76px 0 120px;
  min-height: 96vh;
  background-size: cover;
  -ms-flex-item-align: center;
  -ms-grid-row-align: center;
  align-self: center;
  position: relative;
  background-position: center center;
}

@media (max-width: 767px) {
  .bg-profile {
    height: auto;
  }
}

.cursor-pointer {
  cursor: pointer;
}

.indiehackers-icon {
  width: 20px !important;
  height: 20px !important;
}

.react-calendar-heatmap .color-github-0 {
  fill: #f3f3f3;
}

.react-calendar-heatmap .color-github-1 {
  fill: #ff7f94;
}

.btn.btn-danger.user-btn {
  font-weight: 600;
  background: #f13a59;
  border-color: #f13a59;
  padding: 0.75em;
  font-size: 16px;
  transition: 0.3s linear all;
}
.btn.btn-danger.user-btn:hover {
  background: #e81035;
  border-color: #e81035;
}

.user-bio {
  font-size: 16px;
}

.user-location {
  font-size: 16px !important;
}

.stats-title {
  font-size: 16px;
}

.direct-message {
  display: inline-flex; /* make element size relative to content */
  align-items: center; /* vertical alignment of items */
  border: 1px solid #00acee;
  height: 35px;
  width: 35px;
  border-radius: 50%;
  cursor: pointer;
}

.direct-message:hover {
  background-color: #effffb;
}
