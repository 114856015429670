.bg-dashboard {
  padding: 76px 0 120px;
  background-size: cover;
  -ms-flex-item-align: center;
  -ms-grid-row-align: center;
  align-self: center;
  position: relative;
  background-position: center center;
}

@media (max-width: 767px) {
  .bg-profile {
    height: auto;
  }
}

.cursor-pointer {
  cursor: pointer;
}

.indiehackers-icon {
  width: 20px !important;
  height: 20px !important;
}

.react-calendar-heatmap .color-github-0 {
  fill: #f3f3f3;
}

.react-calendar-heatmap .color-github-1 {
  fill: #ff7f94;
}

.btn.btn-outline-light.product-video-btn {
  border-color: #fff;
  border-radius: 25px;
  background-color: #fff;
  color: rgba(0, 0, 0, 0.85);
  transition: 0.3s linear all;
}

.btn.btn-outline-light.product-video-btn:hover {
  background: rgb(233, 233, 233);
  border-color: #fff;
}

.edit-product {
  cursor: pointer;
  display: inline-block;
  margin: 0 0.75em 0 0;
  color: rgba(0, 0, 0, 0.4);
  font-size: 11px;
  font-weight: 700;
}

.delete-product {
  cursor: pointer;
  display: inline-block;
  margin: 0 0.75em 0 0;
  color: rgba(0, 0, 0, 0.4);
  font-size: 11px;
  font-weight: 700;
  margin-left: 1em;
}
