.bg-home {
  padding: 76px 0 120px;
  min-height: 96vh;
  background-size: cover;
  -ms-flex-item-align: center;
  -ms-grid-row-align: center;
  align-self: center;
  position: relative;
  background-position: center center;
}

.home-banner-title {
  font-size: 46px !important;
  font-weight: 800;
  font-family: system-ui, -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto,
    Helvetica, Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji",
    "Segoe UI Symbol" !important;
}

.lead.text-muted {
  font-weight: 500 !important;
}

.btn.btn-danger.py-2 {
  font-weight: 700;
  background: #f13a59;
  border-color: #f13a59;
  padding: 0.75em 7em;
  transition: 0.3s linear all;
}
.btn.btn-danger.py-2:hover {
  background: #e81035;
  border-color: #e81035;
}

.btn.btn-danger.my-1 {
  font-weight: 700;
  font-size: 16px;
  background: #f13a59;
  border-color: #f13a59;
  padding: 0.75em 1.5em;
  transition: 0.3s linear all;
}
.btn.btn-danger.my-1:hover {
  background: #e81035;
  border-color: #e81035;
}

@media (max-width: 767px) {
  .bg-home {
    height: auto;
  }

  .btn.btn-danger.my-1 {
    padding: 0.75em !important;
  }
}

.jumbotron {
  background: url("https://firebasestorage.googleapis.com/v0/b/lonely-dev.appspot.com/o/assets%2Fbanner.jpg?alt=media&token=12bd1340-07b4-4329-89ce-6170ae635101");
  margin-bottom: 0 !important;
}
