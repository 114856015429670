.profile-container {
  min-height: 95vh;
  display: flex;
  flex-direction: column;
  min-width: 100vw;
  padding: 76px 0 120px;
}

@media (max-width: 767px) {
  .bg-profile {
    padding: 150px 0;
    height: auto;
  }
}

.grey-background {
  background-color: #f6f9fc;
}
