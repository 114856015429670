#topnav {
  position: fixed;
  right: 0;
  left: 0;
  top: 0;
  z-index: 1030;
  background-color: transparent;
  border: 0;
  -webkit-transition: all 0.5s ease;
  transition: all 0.5s ease;
}

.logo-img {
  height: 35px;
  width: 35px;
  background-color: #ffffff;
  margin: auto;
  padding-top: 4px;
  background-size: 100% 100%;
}

#topnav .logo {
  float: left;
  color: #3c4858 !important;
  font-weight: 800;
  margin-left: 10px;
  margin: 0 10px;
}

#topnav .has-submenu.active a {
  color: #ffffff;
}

#topnav .has-submenu.active .submenu li.active > a {
  color: #000000;
}

#topnav .has-submenu.active.active .menu-arrow {
  border-color: #000000;
}

#topnav .has-submenu {
  position: relative;
}

#topnav .has-submenu .submenu .submenu-arrow {
  border: solid #3c4858;
  border-radius: 0.5px;
  border-width: 0 2px 2px 0;
  display: inline-block;
  padding: 3px;
  -webkit-transform: rotate(-45deg);
  transform: rotate(-45deg);
  position: absolute;
  right: 20px;
  top: 14px;
}

#topnav .navbar-toggle {
  border: 0;
  position: relative;
  width: 60px;
  padding: 0;
  margin: 0;
  cursor: pointer;
}

#topnav .navbar-toggle .lines {
  width: 25px;
  display: block;
  position: relative;
  margin: 32px 20px 24px;
  height: 18px;
}

#topnav .navbar-toggle span {
  height: 2px;
  width: 100%;
  background-color: #3c4858;
  display: block;
  margin-bottom: 5px;
  -webkit-transition: -webkit-transform 0.5s ease;
  transition: -webkit-transform 0.5s ease;
  transition: transform 0.5s ease;
  transition: transform 0.5s ease, -webkit-transform 0.5s ease;
}

#topnav .navbar-toggle span:last-child {
  margin-bottom: 0;
}

#topnav .buy-button {
  float: right;
  line-height: 68px;
  padding: 3px 0;
}

#topnav .navbar-toggle.open span {
  position: absolute;
}

#topnav .navbar-toggle.open span:first-child {
  top: 6px;
  -webkit-transform: rotate(45deg);
  transform: rotate(45deg);
}

#topnav .navbar-toggle.open span:nth-child(2) {
  visibility: hidden;
}

#topnav .navbar-toggle.open span:last-child {
  width: 100%;
  top: 6px;
  -webkit-transform: rotate(-45deg);
  transform: rotate(-45deg);
}

#topnav .navbar-toggle.open span:hover {
  background-color: #000000;
}

#topnav .navbar-toggle:hover,
#topnav .navbar-toggle:focus,
#topnav .navbar-toggle .navigation-menu > li > a:hover,
#topnav .navbar-toggle:focus {
  background-color: transparent;
}

#topnav .navigation-menu {
  list-style: none;
  margin: 0;
  padding: 0;
}

#topnav .navigation-menu > li {
  float: left;
  display: block;
  position: relative;
  margin: 0 10px;
}

#topnav .navigation-menu > li:hover > a,
#topnav .navigation-menu > li.active > a {
  color: #000000 !important;
}

#topnav .navigation-menu > li > a {
  display: block;
  color: #3c4858;
  font-size: 16px;
  background-color: transparent !important;
  font-weight: 700;
  line-height: 24px;
  text-transform: capitalize;
  -webkit-transition: all 0.5s;
  transition: all 0.5s;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Helvetica,
    Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol";
  padding-left: 15px;
  padding-right: 15px;
}

#topnav .navigation-menu > li > a:hover,
#topnav .navigation-menu > li > a:active {
  color: #000000;
}

#topnav .navigation-menu .has-submenu .menu-arrow {
  border: solid #3c4858;
  border-radius: 0.5px;
  border-width: 0 2px 2px 0;
  display: inline-block;
  padding: 3px;
  -webkit-transform: rotate(45deg);
  transform: rotate(45deg);
  position: absolute;
  -webkit-transition: all 0.5s;
  transition: all 0.5s;
  right: -1px;
  top: 30px;
}

#topnav .menu-extras {
  float: right;
}

#topnav.scroll {
  background-color: #ffffff;
  border: none;
  -webkit-box-shadow: 0 0 3px rgba(0, 0, 0, 0.15);
  box-shadow: 0 0 3px rgba(0, 0, 0, 0.15);
}

#topnav.scroll .navigation-menu > li > a {
  color: #3c4858;
}

#topnav.scroll .navigation-menu > li > .menu-arrow {
  border-color: #3c4858;
}

#topnav.scroll .navigation-menu > li:hover > a,
#topnav.scroll .navigation-menu > li.active > a {
  color: #000000;
}

#topnav.scroll .navigation-menu > li:hover > .menu-arrow,
#topnav.scroll .navigation-menu > li.active > .menu-arrow {
  border-color: #000000;
}

#topnav.defaultscroll.dark-menubar .logo {
  line-height: 70px;
}

#topnav.defaultscroll.scroll .logo {
  line-height: 62px;
}

#topnav.defaultscroll.scroll.dark-menubar .logo {
  line-height: 62px;
}

#topnav.nav-sticky {
  background: #ffffff;
  -webkit-box-shadow: 0 0 3px rgba(0, 0, 0, 0.15);
  box-shadow: 0 0 3px rgba(0, 0, 0, 0.15);
  padding-right: 20px;
}

.logo {
  font-weight: 700;
  font-size: 24px;
  padding: 0;
  letter-spacing: 1px;
  line-height: 68px;
}

@media (min-width: 992px) {
  #topnav .navigation-menu {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
  }
  #topnav
    .navigation-menu
    > .has-submenu
    > .submenu
    > .has-submenu
    > .submenu::before {
    top: 20px;
    margin-left: -35px;
    -webkit-box-shadow: 1px 1px 0px 0px rgba(22, 28, 45, 0.15);
    box-shadow: 1px 1px 0px 0px rgba(22, 28, 45, 0.15);
    border-color: transparent #ffffff #ffffff transparent;
  }
  #topnav .navigation-menu > li .submenu {
    position: absolute;
    top: 100%;
    left: 0;
    z-index: 1000;
    padding: 15px 0;
    list-style: none;
    min-width: 180px;
    visibility: hidden;
    opacity: 0;
    margin-top: 10px;
    -webkit-transition: all 0.2s ease;
    transition: all 0.2s ease;
    border-radius: 10px;
    background-color: #ffffff;
    -webkit-box-shadow: 0 0 3px rgba(0, 0, 0, 0.15);
    box-shadow: 0 0 3px rgba(0, 0, 0, 0.15);
  }
  #topnav .navigation-menu > li .submenu:before {
    content: "";
    position: absolute;
    top: 2px;
    left: 45px;
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
    border: 7px solid #495057;
    border-color: transparent transparent #ffffff #ffffff;
    -webkit-transform-origin: 0 0;
    transform-origin: 0 0;
    -webkit-transform: rotate(135deg);
    transform: rotate(135deg);
    -webkit-box-shadow: -2px 2px 2px -1px rgba(22, 28, 45, 0.15);
    box-shadow: -2px 2px 2px -1px rgba(22, 28, 45, 0.15);
  }
  #topnav .navigation-menu > li .submenu li {
    position: relative;
  }
  #topnav .navigation-menu > li .submenu li a {
    display: block;
    padding: 10px 20px;
    clear: both;
    white-space: nowrap;
    font-size: 11px;
    text-transform: capitalize;
    letter-spacing: 0.04em;
    font-weight: 700;
    color: #3c4858;
    -webkit-transition: all 0.3s;
    transition: all 0.3s;
  }
  #topnav .navigation-menu > li .submenu li a:hover {
    color: #000000;
  }
  #topnav .navigation-menu > li .submenu li ul {
    list-style: none;
    padding-left: 0;
    margin: 0;
  }
  #topnav .navigation-menu > li .submenu.megamenu {
    white-space: nowrap;
    width: auto;
  }
  #topnav .navigation-menu > li .submenu.megamenu > li {
    overflow: hidden;
    min-width: 180px;
    display: inline-block;
    vertical-align: top;
  }
  #topnav .navigation-menu > li .submenu.megamenu > li .submenu {
    left: 100%;
    top: 0;
    margin-left: 10px;
    margin-top: -1px;
  }
  #topnav .navigation-menu > li .submenu > li .submenu {
    left: 106%;
    top: 0;
    margin-left: 10px;
    margin-top: -1px;
  }
  #topnav .navigation-menu > li > a {
    padding-top: 25px;
    padding-bottom: 25px;
    min-height: 62px;
  }
  #topnav .navigation-menu > li:hover > .menu-arrow {
    border-color: #000000;
  }
  #topnav .navigation-menu > li:hover > a,
  #topnav .navigation-menu > li.active > a {
    color: #000000 !important;
  }
  #topnav .navigation-menu > li.last-elements .submenu {
    left: auto;
    right: 0;
  }
  #topnav .navigation-menu > li.last-elements .submenu:before {
    left: auto;
    right: 10px;
  }
  #topnav
    .navigation-menu
    > li.last-elements
    .submenu
    > li.has-submenu
    .submenu {
    left: auto;
    right: 100%;
    margin-left: 0;
    margin-right: 10px;
  }
  #topnav .navbar-toggle {
    display: none;
  }
  #topnav #navigation {
    display: block !important;
  }
  #topnav.scroll {
    top: 0;
  }
  #topnav.scroll .navigation-menu > li > a {
    padding-top: 20px;
    padding-bottom: 20px;
  }
  #topnav.scroll-active .navigation-menu > li > a {
    padding-top: 25px;
    padding-bottom: 25px;
  }
}

@media (max-width: 991px) {
  #topnav {
    background-color: #ffffff;
    -webkit-box-shadow: 0 0 3px rgba(0, 0, 0, 0.15);
    box-shadow: 0 0 3px rgba(0, 0, 0, 0.15);
    min-height: 74px;
  }
  #topnav .container {
    width: auto;
  }
  #topnav #navigation {
    max-height: 400px;
  }
  #topnav .navigation-menu {
    float: none;
  }
  #topnav .navigation-menu > li {
    float: none;
  }
  #topnav .navigation-menu > li .submenu {
    display: none;
    list-style: none;
    padding-left: 20px;
    margin: 0;
  }
  #topnav .navigation-menu > li .submenu li a {
    display: block;
    position: relative;
    padding: 7px 15px;
    text-transform: capitalize;
    font-size: 11px;
    letter-spacing: 0.04em;
    font-weight: 700;
    color: #3c4858;
    -webkit-transition: all 0.3s;
    transition: all 0.3s;
  }
  #topnav .navigation-menu > li .submenu.open {
    display: block;
  }
  #topnav .navigation-menu > li .submenu .submenu {
    display: none;
    list-style: none;
  }
  #topnav .navigation-menu > li .submenu .submenu.open {
    display: block;
  }
  #topnav .navigation-menu > li .submenu.megamenu > li > ul {
    list-style: none;
    padding-left: 0;
  }
  #topnav .navigation-menu > li .submenu.megamenu > li > ul > li > span {
    display: block;
    position: relative;
    padding: 10px 15px;
    text-transform: capitalize;
    font-size: 12px;
    letter-spacing: 2px;
    color: #8492a6;
  }
  #topnav .navigation-menu > li > a {
    color: #3c4858;
    padding: 10px 20px;
  }
  #topnav .navigation-menu > li > a:after {
    position: absolute;
    right: 15px;
  }
  #topnav .navigation-menu > li > a:hover,
  #topnav .navigation-menu > li .submenu li a:hover,
  #topnav .navigation-menu > li.has-submenu.open > a {
    color: #000000;
  }
  #topnav .menu-extras .menu-item {
    border-color: #8492a6;
  }
  #topnav .navbar-header {
    float: left;
  }
  #topnav .has-submenu .submenu .submenu-arrow {
    -webkit-transform: rotate(45deg);
    transform: rotate(45deg);
    position: absolute;
    right: 20px;
    top: 12px;
  }
  #topnav .has-submenu.active a {
    color: #000000;
  }
  #navigation {
    position: absolute;
    top: 74px;
    left: 0;
    width: 100%;
    display: none;
    height: auto;
    padding-bottom: 0;
    overflow: auto;
    border-top: 1px solid #f1f3f9;
    border-bottom: 1px solid #f1f3f9;
    background-color: #ffffff;
  }
  #navigation.open {
    display: block;
    overflow-y: auto;
  }
}

@media (max-width: 768px) {
  #topnav .navigation-menu .has-submenu .menu-arrow {
    right: 16px;
    top: 16px;
  }
}

@media (min-width: 768px) {
  #topnav .navigation-menu > li.has-submenu:hover > .submenu {
    visibility: visible;
    opacity: 1;
    margin-top: 0;
  }
  #topnav
    .navigation-menu
    > li.has-submenu:hover
    > .submenu
    > li.has-submenu:hover
    > .submenu {
    visibility: visible;
    opacity: 1;
    margin-left: 0;
    margin-right: 0;
  }
  .navbar-toggle {
    display: block;
  }
}

@media (max-width: 375px) {
  #topnav .buy-button {
    display: none;
  }
  #topnav .buy-menu-btn {
    display: block !important;
    margin: 0 10px;
    padding: 10px 20px;
  }
  #topnav .navbar-toggle .lines {
    margin-right: 0 !important;
  }
}

/* Log in button */
.login-button {
  float: right;
  line-height: 68px;
  padding: 3px 0;
  margin-right: 1rem;
}

.btn.btn-outline-primary {
  color: #f13a59;
  border-color: #f13a59;
}

.btn.btn-outline-primary:hover {
  color: #fff;
  background-color: #e81035;
  border-color: #e81035;
}

#notification-dropdown .ui.dropdown .menu > .header {
  margin: 0;
  padding: 0 1rem;
  font-size: 1em;
}

#notification-dropdown .ui.top.right.pointing.dropdown > .menu {
  width: 360px;
}

.ui.avatar.image,
.ui.avatar.image img,
.ui.avatar.image svg,
.ui.avatar.images .image,
.ui.avatar.images img,
.ui.avatar.images svg {
  width: 2.5em;
  height: 2.5em;
}

.fab-menu {
  position: fixed;
  bottom: 3em;
  right: 3em;
  z-index: 1031;
}

.fab-child-btn {
  background: linear-gradient(
    45deg,
    rgb(254, 107, 139) 30%,
    rgb(255, 142, 83) 90%
  );
}

.fab-main-btn {
  background: linear-gradient(
    45deg,
    rgb(254, 107, 139) 30%,
    rgb(255, 142, 83) 90%
  );
  color: #fff;
}

.MuiTooltip-tooltipPlacementLeft {
  margin: 20px;
}
