.challenge-card {
  border: 1px solid hsla(206, 57%, 12%, 0.1);
  border-radius: 5px;
  padding: 1.6rem !important;
  height: 100%;
  flex-direction: column;
  display: flex;
}

.challenge-card:hover {
  box-shadow: 0 12px 54px 0 hsla(206, 57%, 12%, 0.1);
  border: 1px solid hsla(206, 57%, 12%, 0.1);
}

img.challenge-badge {
  border-radius: 50%;
  width: 150px;
  margin-top: 35px;
  margin-bottom: 20px;
  cursor: pointer;
}

.container-fluid {
  padding-right: 0 !important;
}

.challenge-title {
  font-size: 30px;
  font-weight: 700;
  color: #30475e;
}

.challenge-description {
  color: #30475e;
}

.stat-number {
  font-weight: 600;
  font-size: 20px !important;
  color: #30475e;
}

.channel-pic {
  height: 80px;
  width: 80px;
  background-color: gray;
  margin: 4px 0 4px;
  background-size: 100% 100%;
}

.btn.btn-danger.my-1 {
  padding: 0.75em;
  width: 180px !important;
}

.btn.btn-primary.my-1 {
  font-weight: 700;
  padding: 0.75em;
  transition: 0.3s linear all;
  width: 180px !important;
}

.btn.btn-outline-danger.my-1 {
  font-weight: 700;
  border-color: #f13a59;
  padding: 0.75em 1.5em;
  transition: 0.3s linear all;
}
.btn.btn-outline-danger.my-1:hover {
  background-color: #f13a59;
  border-color: #f13a59;
}

.btn.btn-secondary.my-1 {
  font-weight: 700;
  font-size: 16px;
  padding: 0.75em;
  transition: 0.3s linear all;
  width: 180px !important;
}

.card-btn-group {
  display: flex;
  align-items: flex-start;
  justify-content: space-around;
}

.lets-go img {
  max-width: 100%;
  width: 300px;
  height: auto;
  margin: 20px auto;
}

@media (max-width: 600px) {
  .btn.btn-danger.my-1 {
    width: 140px !important;
  }

  .btn.btn-primary.my-1 {
    width: 140px !important;
  }

  .btn.btn-secondary.my-1 {
    width: 140px !important;
  }
}

.leaderboard-table {
  margin: 20px auto;
}

@media (min-width: 1200px) {
  .leaderboard-table {
    width: 1000px;
  }
}
